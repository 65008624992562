import React, { useState, useEffect, FC } from "react";
import Content from "../../layout/content/Content";
import { Card, Row, Col, Button } from "reactstrap";
import {
  TableColumnsType,
  Table,
  Progress,
  Select,
  Input,
  Flex,
  Spin,
  Empty,
} from "antd";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  TooltipComponent,
} from "../../components/Component";
import FormatDate from "../masters/DateFormater";
import PurchaseOrderAside from "./PurchaseOrderAside";
import ViewOrderDetails from "./ViewOrderDetails";
import { SearchOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import purchaseOrderApis from "../../api/purchase-order/PurchaseOrder";
import statusMapper from "./StatusMapper";
import { useNavigate } from "react-router";
import { formatCurrency } from "../../utils/Utils";
import TransactionFlowDefaultTemplate from "../others/transactionFlowDefaultTemplate";
const { Option } = Select;
interface WidgetData {
  title: string;
  count: number;
  progress: number;
  color: string;
}

const ViewPurchaseOrdersList: FC = () => {
  const [sm, setSm] = useState(false);
  const navigate = useNavigate();
  const [widgetData, setWidgetData] = useState<any>({});
  const [orderStatus, setOrderStatus] = useState<any>([]);
  const [columns, setColumns] = useState<TableColumnsType<any>>([
    {
      title: "#",
      key: "#",
      width: 50,
      fixed: "left",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Order #",
      dataIndex: "orderId",
      key: "orderId",
      width: 200,
      fixed: "left",
      render: (text) => (
        <div className="align-left">
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: "Order Date",
      dataIndex: "orderDate",
      key: "orderDate",
      width: 120,
      fixed: "left",
      render: (date) => <span>{FormatDate(date)}</span>,
    },
    {
      title: "Customer",
      dataIndex: "orgName",
      key: "orgName",
      width: 250,
      render: (text: string, record: any, index: any) => {
        return (
          <>
            <span id={`customer-tooltip-${index}`}>
              {text?.length > 20 ? `${text?.substring(0, 25)}... ` : text}
            </span>
            {text?.length > 20 && (
              <TooltipComponent
                iconClass="info-circle text-info"
                icon="info"
                id={`customer-tooltip-${index}`}
                direction="top"
                text={text}
                containerClassName={""}
              />
            )}
          </>
        );
      },
    },
    {
      title: "Site",
      dataIndex: "siteName",
      key: "siteName",
      width: 200,
    },
    {
      title: "Requested Delivery Date",
      dataIndex: "needByDate",
      key: "needByDate",
      width: 200,
      render: (date) => <span>{date ? FormatDate(date) : "N/A"}</span>,
    },
    {
      title: "Basic Amount",
      dataIndex: "basicAmount",
      key: "basicAmount",
      width: 200,
      render: (text, record) => (
        <div style={{ textAlign: "right" }}>
          <span>{formatCurrency(text, record.currencyCode)}</span>
        </div>
      ),
    },

    {
      title: "SGST",
      dataIndex: "sgst",
      key: "sgst",
      width: 200,
      render: (text, record) => (
        <div style={{ textAlign: "right" }}>
          <span>
            {Number(record?.sgst) && Number(record?.cgst)
              ? formatCurrency(text, record.currencyCode)
              : "N/A"}
          </span>
        </div>
      ),
    },
    {
      title: "CGST",
      dataIndex: "cgst",
      key: "cgst",
      width: 200,
      render: (text, record) => (
        <div style={{ textAlign: "right" }}>
          <span>
            {Number(record?.sgst) && Number(record?.cgst)
              ? formatCurrency(text, record.currencyCode)
              : "N/A"}
          </span>
        </div>
      ),
    },
    {
      title: "IGST",
      dataIndex: "igst",
      key: "igst",
      width: 200,
      render: (text, record) => (
        <div style={{ textAlign: "right" }}>
          <span>
            {!Number(record?.sgst) && !Number(record?.cgst)
              ? formatCurrency(text, record.currencyCode)
              : "N/A"}
          </span>
        </div>
      ),
    },

    {
      title: "Gross Total",
      dataIndex: "grossTotal",
      key: "grossTotal",
      width: 250,
      render: (text, record) => (
        <div style={{ textAlign: "right" }}>
          <span>{formatCurrency(text, record.currencyCode)}</span>
        </div>
      ),
    },
    {
      title: "Created By",
      dataIndex: "createdUserName",
      key: "createdUserName",
      width: 200,
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (date) => <span>{FormatDate(date)}</span>,
      width: 100,
    },
    {
      title: "GRN Status",
      dataIndex: "deliveryStatus",
      key: "deliveryStatus",
      width: 200,
      render(value: any, record: any, index: any) {
        const grnStatus: any = {
          FullyDelivered: "Fully Delivered",
          PartiallyDelivered: "Partially Delivered",
        };
        return <p className="tb-status">{grnStatus[value] || value}</p>;
      },
    },
    {
      title: "Order Status",
      dataIndex: "orderStatus",
      key: "orderStatus",
      width: 200,
      render: (status: any) => {
        const _status = statusMapper[status]?.seller;
        return (
          <div className="justify-start align-center">
            <h6
              className={`tb-status ms-1`}
              style={{
                color: `${_status?.color}`,
                fontSize: "14px",
              }}
            >
              {_status?.icon} {_status?.title || status}
            </h6>
          </div>
        );
      },
    },

    {
      title: "Actions",
      key: "actions",
      fixed: "right",
      width: 80,
      render: (text: any, record: any) => (
        <>
          <Button
            size="sm"
            color=""
            onClick={() => {
              navigate(`/view-purchase-order/${record.orderNumber}`, {
                state: {
                  previousPath: "/view-purchase-order",
                },
              });
            }}
          >
            <Icon name="focus" />
          </Button>
        </>
      ),
    },
  ]);

  useEffect(() => {
    fetchWidgetCount();
    fetchDerivedOrderStatusDropdown();
  }, []);

  const fetchWidgetCount = async () => {
    try {
      const res = await purchaseOrderApis.fetchWidgetCount();
      const { data, status, message } = res;
      if (status) {
        setWidgetData(data);
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (error) {}
  };

  const fetchDerivedOrderStatusDropdown = async () => {
    try {
      const res = await purchaseOrderApis.fetchDerivedOrderStatusDropdown();
      console.log("status");
      const { data, status, message } = res;
      if (status) {
        let options = [];
        let dateresp = Object.keys(data).map((key) => {
          return {
            label: key,
            value: data[key],
          };
        });
        console.log("dateresp", dateresp);
        setOrderStatus(dateresp);
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (error) {}
  };

  return (
    <TransactionFlowDefaultTemplate
      moduleKey="view-purchase-order"
      title="Purchase Orders"
      columns={columns}
      sideNavigation={
        <div
          className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
            sm ? "content-active" : ""
          }`}
        >
          <PurchaseOrderAside updateSm={setSm} sm={sm} />
        </div>
      }
      selectFilters={[
        {
          title: "Order Status",
          placeholder: "Select Status",
          options: orderStatus,
        }
      ]}
      extra={{
        tableScrollX: 1100,
        dateRange: true,
        excelExport: true,
        dataFilterTootip: "Filtering based on Purchase Order date",
      }}
      widget={{
        isActive: true,
        list: [
          {
            title: "Pending",
            key: "pending",
            color: "#OO65BD",
            percentage: `${widgetData?.pendingPercentage || 0}`,
            value: `${widgetData?.pendingCount || 0}`,
          },
          {
            title: "Changes Requested",
            key: "changes",
            color: "#FA991C",
            percentage: `${widgetData?.changesPercentage || 0}`,
            value: `${widgetData?.changesCount || 0}`,
          },
          {
            title: "Buyer Accepted Changes",
            key: "buyerAcceptedChanges",
            color: "#FA991C",
            percentage: `${widgetData?.buyerAcceptedChangesPercentage || 0}`,
            value: `${widgetData?.buyerAcceptedChangesCount || 0}`,
          },
          {
            title: "Changes from Buyer",
            key: "changesFromBuyer",
            color: "#FA991C",
            percentage: `${widgetData?.changesFromBuyerPercentage || 0}`,
            value: `${widgetData?.changesFromBuyerCount || 0}`,
          },
          {
            title: "To be Invoiced",
            key: "invoiced",
            color: "#FA991C",
            percentage: `${widgetData?.invoicedPercentage || 0}`,
            value: `${widgetData?.invoicedCount || 0}`,
          },
        ],
      }}
    />
  );
};

export default ViewPurchaseOrdersList;
