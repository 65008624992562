import makeApiCall from "../master/axiosIndex";

const paymentApis = {
  fetchPayment: (paymentNumber: string): Promise<any> =>
    makeApiCall<any>({
      url: `/payment/fetchPayment?paymentNumber=${paymentNumber}`,
      method: "get",
    }),
  fetchAllPayments: (): Promise<any> =>
    makeApiCall<any>({
      url: "/payment/fetchAllPendingConfirmationPayments",
      method: "get",
    }),
  fetchAllInvoicePaymentLines: (invoiceNumber: string): Promise<any> =>
    makeApiCall<any>({
      url: `/payment/fetchAllInvoicePaymentLines?invoiceNumber=${invoiceNumber}`,
      method: "get",
    }),
  confirmPayment: (data: any): Promise<any> =>
    makeApiCall<any>({
      url: "/payment/confirmPayment",
      method: "post",
      data,
    }),
  disapprovePayment: (
    paymentNumber: string,
    rejectedReason: string
  ): Promise<any> =>
    makeApiCall<any>({
      url: `/payment/rejectPayment?paymentNumber=${paymentNumber}&rejectedReason=${rejectedReason}`,
      method: "post",
    }),
  fetchAllPaidInvoicesWithPagination: (params: any): Promise<any> =>
    makeApiCall<any>({
      url: "/billing/fetchAllPaidInvoicesWithPagination",
      method: "get",
      params,
    }),
  fetchAllPendingConfirmationPaymentsWithPagination: (
    params: any
  ): Promise<any> =>
    makeApiCall<any>({
      url: "/payment/fetchAllPendingConfirmationPaymentsWithPagination",
      method: "get",
      params,
    }),
};

export default paymentApis;
